@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  font-family: 'Noto Sans KR', sans-serif;
  -webkit-tap-highlight-color: transparent;
}

body {
  color: #383838;
}
